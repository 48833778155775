import { Link } from 'gatsby'
import styled from 'styled-components'
import colors from 'src/assets/styles/colors.js'
import typographySizes from 'src/assets/styles/typographySizes.js'

const SmallLink = styled(Link)`
  font-size: ${typographySizes.s}rem;
  color: ${colors.white};
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  :hover {
    transform: translateY(-5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    color: ${colors.mainColor};
  }
`

export default SmallLink
