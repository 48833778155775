const distances = {
  s: 20,
  m: 50,
  sectionBigMargin: 150,
  barW: 12,
  mFromTop: 120,
  mBottomPage: 120,
}

export default distances
