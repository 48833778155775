import Font from 'src/components/p5js/Termina.otf'

const Sketch = p => {
  let pg
  let font
  let xoff = 0.0

  let tiles = 100
  let tilesY
  let tilesX = 1
  let tileSizeX
  let tileSizeY
  let tileSize
  let loopDuration = 2 * 30
  let unitBaseOnW

  p.preload = function () {
    font = p.loadFont(Font)
  }

  p.setup = function () {
    unitBaseOnW = p.windowWidth * 0.00535
    p.createCanvas(p.windowWidth, unitBaseOnW * 40)
    p.background(125)

    tilesY = 120
    tileSizeX = p.width / tilesX
    tileSizeY = p.height / tilesY
    pg = p.createGraphics(p.windowWidth, p.windowHeight)
    pg.textFont(font)
    pg.textAlign(p.CENTER, p.CENTER)
    pg.blendMode(p.SCREEN)
    pg.translate(
      p.width / 2 - unitBaseOnW * 1.85,
      p.height / 2 - unitBaseOnW * 10,
    )
    pg.textSize(unitBaseOnW * 51)

    pg.fill(0)
    pg.text('NBCT', 0, 0)

    tileSize = p.width / tiles

    p.frameRate(30)
  }

  p.draw = function () {
    p.background(255)

    for (let x = 0; x < tilesX; x++) {
      for (let y = 0; y < tilesY; y++) {
        // const waveX = tan(currentFrame/2 * 0.01 * ( x * y ) * 0.01) * -5

        let dist = p.dist(0, y * tileSizeY, 0, p.mouseY)
        let opDist = p.map(dist, 0, p.height, 3, 1)

        let mousePow = 0

        if (p.mouseY >= -100) {
          mousePow = p.sin(p.pow(opDist, 10) / 4000) * 20
        }

        let waveX =
          p.sin(p.frameCount * x * p.noise(xoff * 1) + p.frameCount * y * 0.1) *
            4 +
          mousePow

        const sx = x * tileSizeX + waveX
        const sy = y * tileSizeY
        const sw = tileSizeX
        const sh = tileSizeY
        const dx = x * tileSizeX

        const dy = y * tileSizeY
        const dw = tileSizeX
        const dh = tileSizeY

        // p.image(pg, dx, dy, dw, dh, sx, sy, sw, sh)
        if (sx < 0) {
          // Since the source is left of zero we want to take this content and
          // shift it to the right, we do this by using a source x of 0 and
          // increasing the destination x by -sx
          p.image(pg, dx - sx, dy, dw + sx, dh, 0, sy, sw + sx, sh)
        } else {
          // when sx is positive we just need to make sure the source width
          // isn't too big
          p.image(pg, dx, dy, dw - sx, dh, sx, sy, sw - sx, sh)
        }
      }
    }

    xoff = xoff + 0.01
  }

  p.windowResized = function () {
    unitBaseOnW = p.windowWidth * 0.00535
    p.createCanvas(p.windowWidth, unitBaseOnW * 40)
    p.background(125)

    tilesY = 240
    tileSizeX = p.width / tilesX
    tileSizeY = p.height / tilesY
    pg = p.createGraphics(p.windowWidth, p.windowHeight)
    pg.textFont(font)
    pg.textAlign(p.CENTER, p.CENTER)
    pg.blendMode(p.SCREEN)
    pg.translate(
      p.width / 2 - unitBaseOnW * 1.85,
      p.height / 2 - unitBaseOnW * 10,
    )
    pg.textSize(unitBaseOnW * 51)

    pg.fill(0)
    pg.text('NBCT', 0, 0)

    tileSize = p.width / tiles

    p.frameRate(30)
  }
}

export default Sketch
