// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-album-layout-js": () => import("./../../../src/layouts/albumLayout.js" /* webpackChunkName: "component---src-layouts-album-layout-js" */),
  "component---src-layouts-artist-layout-js": () => import("./../../../src/layouts/artistLayout.js" /* webpackChunkName: "component---src-layouts-artist-layout-js" */),
  "component---src-layouts-team-layout-js": () => import("./../../../src/layouts/teamLayout.js" /* webpackChunkName: "component---src-layouts-team-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artysci-js": () => import("./../../../src/pages/artysci.js" /* webpackChunkName: "component---src-pages-artysci-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konsultacje-js": () => import("./../../../src/pages/konsultacje.js" /* webpackChunkName: "component---src-pages-konsultacje-js" */),
  "component---src-pages-konsultacje-potwierdzenie-js": () => import("./../../../src/pages/konsultacje-potwierdzenie.js" /* webpackChunkName: "component---src-pages-konsultacje-potwierdzenie-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-legacy-js": () => import("./../../../src/pages/legacy.js" /* webpackChunkName: "component---src-pages-legacy-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-onas-js": () => import("./../../../src/pages/onas.js" /* webpackChunkName: "component---src-pages-onas-js" */),
  "component---src-pages-rezerwacja-js": () => import("./../../../src/pages/rezerwacja.js" /* webpackChunkName: "component---src-pages-rezerwacja-js" */),
  "component---src-pages-rezerwacja-test-js": () => import("./../../../src/pages/rezerwacja-test.js" /* webpackChunkName: "component---src-pages-rezerwacja-test-js" */),
  "component---src-pages-voucher-js": () => import("./../../../src/pages/voucher.js" /* webpackChunkName: "component---src-pages-voucher-js" */),
  "component---src-pages-wycen-swoj-mix-js": () => import("./../../../src/pages/wycen-swoj-mix.js" /* webpackChunkName: "component---src-pages-wycen-swoj-mix-js" */)
}

