import styled from 'styled-components'
import distances from 'src/assets/styles/distances.js'

import mediaQuery from 'src/assets/styles/mediaQuery'

const MainWrapper = styled.div`
  margin: 0 ${distances.s}rem;
  width: calc(100% - ${distances.s * 2}rem);
  position: relative;
  height: 100%;
  @media (max-width: ${mediaQuery.tablet}) {
    margin: 0 ${distances.s / 2}rem;
    width: calc(100% - ${distances.s}rem);
  }
`

export default MainWrapper
