import { createGlobalStyle } from 'styled-components'
import colors from 'src/assets/styles/colors.js'

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        font-display: swap;
        font-family: 'termina', sans-serif;
        color: ${colors.black};
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-size: 1px;
    }
    ::selection {
        color: ${colors.white};
        background: ${colors.mainColor};
}

span::selection {
        color: ${colors.white} !important;
        background: ${colors.mainColor} !important;;
}

a{
    text-decoration: none;
}
 body{
     overflow-x: hidden;
     
 }

 body::-webkit-scrollbar {
  display: none;
}

 strong {

    }

    button{
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
    }
    button:focus {
        outline: none;
        box-shadow: none;
        }
`

export default GlobalStyle
