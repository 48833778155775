import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import ContextConsumer from 'src/layouts/Context.js'
import styled, { keyframes } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import distances from 'src/assets/styles/distances.js'
import colors from 'src/assets/styles/colors.js'
import typographySizes from 'src/assets/styles/typographySizes.js'
import mediaQuery from 'src/assets/styles/mediaQuery'
import MainWrapper from 'src/components/global/MainWrapper.js'
import Hamburger from 'src/components/global/Hamburger.js'
import UnderlineLink from 'src/components/global/buttons/UnderlineLink.js'
import SmallLink from 'src/components/global/buttons/SmallLink.js'
import logo from 'src/assets/svg/logo_nobocoto.svg'
import arrow from 'src/assets/svg/nav-arrow.svg'
import dot from 'src/assets/svg/dot-nav.svg'
import sygnet from 'src/assets/svg/sygnet.svg'
import Div100vh from 'react-div-100vh'

const NavWrapper = styled.nav`
  position: fixed;
  background-color: ${colors.black};
  height: 70rem;
  width: 100vw;
  z-index: 100;

  ${MainWrapper} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.black};
    padding: 0 ${distances.s}rem;
    margin: 0;
    width: calc(100% - ${distances.s * 2}rem);
    z-index: 2;
    @media (max-width: ${mediaQuery.tablet}) {
      padding: 0 ${distances.s / 2}rem;
      width: calc(100% - ${distances.s}rem);
    }
  }
`

const BarRightWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: 2rem;
`

const LogoLink = styled(Link)`
  z-index: 999;
  height: 30rem;
`

const StyledLogo = styled.img`
  height: 30rem;
  position: relative;
  top: 5rem;
  @media (max-width: ${mediaQuery.mobile}) {
    height: 20rem;
    top: 10rem;
  }
`
const NavBottomBarWrapper = styled.div`
  background-color: ${colors.black};
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 10rem ${distances.s / 2}rem;
  display: none;
  flex-direction: row;
  width: calc(100% - ${distances.s}rem);
  border-top: solid 1rem ${colors.mainColor};
  z-index: -1;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  @media (max-width: ${mediaQuery.tablet}) {
    display: flex;
    &.scrollDown {
      transform: translateY(-100%);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`

const NavContentWrapper = styled(Div100vh)`
  position: fixed;
  top: 70rem;
  left: 0;

  background-color: ${colors.black};

  width: 100%;
  z-index: 15;
  transform: translateY(calc(-100% - 70rem));
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  ${MainWrapper} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.show {
    transform: translateY(0);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    top: 124rem;

    transform: translateY(calc(-100% - 124rem));
  }
`

const NavContentWrapperAd = styled.div`
  height: calc(100% - 70rem);
  @media (max-width: ${mediaQuery.tablet}) {
    height: calc(100% - 124rem);
  }
`

const NavList = styled.div`
  position: relative;
  top: 2rem;
  ::before {
    content: '';
    position: absolute;
    left: -${distances.s}rem;
    bottom: 0;
    height: 1rem;
    width: calc(100% + ${distances.s * 2}rem);
    background-color: ${colors.white};
    opacity: 0.5;
    z-index: 10;
  }
  ::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2rem;
    height: 4rem;
    width: 100%;
    background-image: url(${dot});
    background-position: center;
  }
`

const BottomWrapper = styled.div`
  width: 100%;
  margin-bottom: ${distances.s}rem;
  display: flex;
  justify-content: space-between;
`

const SocialWrapper = styled.div`
  display: flex;
  padding-left: ${distances.barW * 2}rem;
  position: relative;
  align-self: flex-end;
  ::before {
    content: '';
    width: ${distances.barW}rem;
    height: calc(100% + ${distances.barW}rem);
    background-color: ${colors.white};
    position: absolute;
    left: 0;
    top: -${distances.barW / 2}rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-wrap: wrap;
  }
`
const SocialLink = styled(SmallLink)`
  margin-right: 30rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 10rem;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: 9rem;
    margin-right: 10rem;
  }
`

const Sygnet = styled.img`
  height: 150rem;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`

// NavElement

const NavElementWrapper = styled(Link)`
  color: ${colors.mainColor};
  font-size: ${typographySizes.l}rem;
  background-color: ${colors.black};
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  display: block;
  position: relative;
  left: -${distances.s}rem;
  padding-left: ${distances.s}rem;
  width: calc(100% + ${distances.s}rem);
  position: relative;

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  &:nth-of-type(1) .show {
    transition: all 0.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &:nth-of-type(2) .show {
    transition: all 0.5s 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &:nth-of-type(3) .show {
    transition: all 0.5s 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &:nth-of-type(4) .show {
    transition: all 0.5s 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &:nth-of-type(5) .show {
    transition: all 0.5s 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &:nth-of-type(5) .show {
    transition: all 0.5s 0.7s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.show {
    transform: translateY(0%);
  }

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 1rem;
    width: 100%;
    background-color: ${colors.white};
    opacity: 0.5;
    z-index: 10;
  }
  ::after {
    content: '';
    position: absolute;
    left: 0;
    top: -2rem;
    height: 4rem;
    width: 100%;
    background-image: url(${dot});
    background-position: center;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: 60rem;
    display: flex;
    align-items: center;
  }
`

const moveFromTop = keyframes`
  from {

    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }

  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`

const moveToTop = keyframes`
  from {

    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  }
`

const moveFromBottom = keyframes`
  from {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }

  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
`

const moveToBottom = keyframes`
  from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  to {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
`

const Mask = styled.div`
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${colors.secColor};
  left: -${distances.s}rem;
  padding: 0 ${distances.s}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  span {
    color: ${colors.black};
    font-size: ${typographySizes.l}rem;
    background-color: '#ffffff';
    font-weight: 700;
    padding-left: ${distances.s}rem;
    position: relative;
    @media (max-width: ${mediaQuery.tablet}) {
      font-size: ${typographySizes.m}rem;
    }
  }
  &.top {
    animation: ${moveFromTop} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
  &.bottom {
    animation: ${moveFromBottom} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
  &.toBottom {
    animation: ${moveToBottom} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
  &.toTop {
    animation: ${moveToTop} 0.3s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
`

const NavElText = styled.span`
  color: ${colors.mainColor};
  font-size: ${typographySizes.l}rem;
  display: inline-block;
  background-color: '#ffffff';
  font-weight: 700;
  transform: translateY(-120%);
  transition: all 0.5s 0.4s cubic-bezier(0.65, 0, 0.35, 1);

  &.show {
    transform: translateY(0%);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
  }
`

const NavElTextWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${mediaQuery.tablet}) {
    display: flex;
    align-items: center;
    width: 100%;
  }
`

const ArrowNav = styled.img`
  width: 36rem;
  height: 36rem;
  padding-right: ${distances.s}rem;
`

const NavElementWrapperExternal = styled.a`
  font-size: ${typographySizes.l}rem;
  background-color: ${colors.mainColor};
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  display: block;
  position: relative;
  left: -${distances.s}rem;
  padding-left: ${distances.s}rem;
  width: calc(100% + ${distances.s}rem);
  position: relative;

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  ${NavElText} {
    color: ${colors.black};
  }

  &:nth-of-type(5) .show {
    transition: all 0.5s 0.7s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.show {
    transform: translateY(0%);
  }

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 1rem;
    width: 100%;
    background-color: ${colors.white};
    opacity: 0.5;
    z-index: 10;
  }
  ::after {
    content: '';
    position: absolute;
    left: 0;
    top: -2rem;
    height: 4rem;
    width: 100%;
    background-image: url(${dot});
    background-position: center;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    height: 60rem;
    display: flex;
    align-items: center;
  }
`

const NavElement = ({ children, to, external }) => {
  const [direction, setDirection] = useState('')
  const [animationEnded, setAnimationEnded] = useState(false)

  const relativeCoords = e => {
    const bounds = e.target.getBoundingClientRect()
    const h = bounds.height
    const y = e.clientY - bounds.top

    if (y > h / 2) {
      setDirection('bottom')
    } else {
      setDirection('top')
    }

    setAnimationEnded(
      setTimeout(() => {
        return true
      }, 310),
    )
  }

  const relativeCoordsLeave = e => {
    const bounds = e.target.getBoundingClientRect()
    const h = bounds.height
    const y = e.clientY - bounds.top

    const intervalId = setInterval(() => {
      if (animationEnded) {
        if (y > h / 2) {
          setDirection('toBottom')
        } else {
          setDirection('toTop')
        }
        clearInterval(intervalId)
      }
    }, 10)
  }

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <NavElementWrapper
          onMouseEnter={relativeCoords}
          onMouseLeave={relativeCoordsLeave}
          to={to}
          onClick={() => {
            set({ navOpen: data.navOpen ? false : true })
          }}
        >
          <Mask className={direction}>
            <span>{children}</span>
          </Mask>
          <NavElTextWrapper>
            <NavElText className={data.navOpen && 'show'}>{children}</NavElText>
          </NavElTextWrapper>
        </NavElementWrapper>
      )}
    </ContextConsumer>
  )
}

const NavElementExternal = ({ children, to }) => {
  const [direction, setDirection] = useState('')
  const [animationEnded, setAnimationEnded] = useState(false)

  const relativeCoords = e => {
    const bounds = e.target.getBoundingClientRect()
    const h = bounds.height
    const y = e.clientY - bounds.top

    if (y > h / 2) {
      setDirection('bottom')
    } else {
      setDirection('top')
    }

    setAnimationEnded(
      setTimeout(() => {
        return true
      }, 310),
    )
  }

  const relativeCoordsLeave = e => {
    const bounds = e.target.getBoundingClientRect()
    const h = bounds.height
    const y = e.clientY - bounds.top

    const intervalId = setInterval(() => {
      if (animationEnded) {
        if (y > h / 2) {
          setDirection('toBottom')
        } else {
          setDirection('toTop')
        }
        clearInterval(intervalId)
      }
    }, 10)
  }

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <NavElementWrapperExternal
          onMouseEnter={relativeCoords}
          onMouseLeave={relativeCoordsLeave}
          href={to}
          target="_blank"
        >
          <Mask className={direction}>
            <span>{children}</span>
            <ArrowNav src={arrow}></ArrowNav>
          </Mask>
          <NavElTextWrapper>
            <NavElText className={data.navOpen && 'show'}>{children}</NavElText>
            <ArrowNav src={arrow}></ArrowNav>
          </NavElTextWrapper>
        </NavElementWrapperExternal>
      )}
    </ContextConsumer>
  )
}

const Nav = () => {
  const [navOnHover, setNavOnHover] = useState(false)
  const [scrollDown, setScrollDown] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)
  let intViewportWidth = 0
  const FnNavOnHover = () => {
    setNavOnHover(true)
  }
  const FnNavOffHover = () => {
    setNavOnHover(false)
  }
  const isBrowser = typeof window !== 'undefined'
  if (isBrowser) {
    intViewportWidth = window.innerWidth
  }

  useEffect(() => {
    if (isBrowser && intViewportWidth < 900) {
      window.onscroll = function (e) {
        if (document.body.getBoundingClientRect().top > scrollTop) {
          setScrollDown(false)
          setScrollTop(document.body.getBoundingClientRect().top)
        } else if (document.body.getBoundingClientRect().top < 0) {
          // saves the new position for iteration.

          setScrollDown(true)
          setScrollTop(document.body.getBoundingClientRect().top)
        }
      }
    }
  }, [scrollTop])

  return (
    <StaticQuery
      query={graphql`
        query Nav {
          strapiGeneral {
            Navigation_Link
            Navigation_Link_Label
            Social_media {
              Link
              Name
            }
          }
        }
      `}
      render={queryData => (
        <>
          <NavWrapper onMouseEnter={FnNavOnHover} onMouseLeave={FnNavOffHover}>
            <ContextConsumer>
              {({ data, set }) => (
                <>
                  <MainWrapper>
                    <LogoLink
                      to="/"
                      onClick={() => {
                        set({ navOpen: false })
                      }}
                    >
                      <StyledLogo src={logo} alt="Nobocoto Logo" />;
                    </LogoLink>

                    <BarRightWrapper>
                      <UnderlineLink offMobile to="/voucher">
                        kup voucher
                      </UnderlineLink>
                      <UnderlineLink offMobile to="/rezerwacja">
                        zarezerwuj studio
                      </UnderlineLink>
                      <UnderlineLink offMobile to="/wycen-swoj-mix">
                        wyceń swój mix
                      </UnderlineLink>
                      <UnderlineLink offMobile to="/konsultacje">
                        umów konsultacje
                      </UnderlineLink>
                      <Hamburger navOnHover={navOnHover} />
                    </BarRightWrapper>
                  </MainWrapper>

                  <NavBottomBarWrapper
                    className={
                      scrollDown && data.navOpen !== true ? 'scrollDown' : ''
                    }
                  >
                    <UnderlineLink to="/voucher">kup voucher</UnderlineLink>
                    <UnderlineLink to="/rezerwacja">
                      zarezerwuj studio
                    </UnderlineLink>
                    <UnderlineLink to="/wycen-swoj-mix">
                      wyceń swój mix
                    </UnderlineLink>
                    <UnderlineLink to="/konsultacje">
                      umów konsultacje
                    </UnderlineLink>
                  </NavBottomBarWrapper>
                </>
              )}
            </ContextConsumer>
          </NavWrapper>
          <ContextConsumer>
            {({ data }) => (
              <NavContentWrapper className={data.navOpen && 'show'}>
                <NavContentWrapperAd>
                  <MainWrapper>
                    <NavList>
                      <NavElement to="/artysci"> artyści </NavElement>
                      <NavElement to="/oferta"> OFERTA </NavElement>
                      <NavElement to="/onas"> O nas </NavElement>
                      <NavElement to="/kontakt"> kontakt</NavElement>
                      {queryData.strapiGeneral.Navigation_Link_Label !== '' &&
                      queryData.strapiGeneral.Navigation_Link_Label !== null ? (
                        <NavElementExternal
                          to={queryData.strapiGeneral.Navigation_Link}
                        >
                          {queryData.strapiGeneral.Navigation_Link_Label}
                        </NavElementExternal>
                      ) : null}
                    </NavList>
                    <BottomWrapper>
                      <SocialWrapper>
                        {queryData.strapiGeneral.Social_media.map(pos => (
                          <SocialLink
                            target="_blank"
                            to={pos.Link}
                            rel="noopener"
                          >
                            {pos.Name}
                          </SocialLink>
                        ))}
                      </SocialWrapper>
                      <Sygnet src={sygnet} />
                    </BottomWrapper>
                  </MainWrapper>
                </NavContentWrapperAd>
              </NavContentWrapper>
            )}
          </ContextConsumer>
        </>
      )}
    />
  )
}

export default Nav
