import React, { useState } from 'react'
import ContextConsumer from 'src/layouts/Context.js'

import styled, { keyframes } from 'styled-components'
import distances from 'src/assets/styles/distances.js'
import colors from 'src/assets/styles/colors.js'
import typographySizes from 'src/assets/styles/typographySizes.js'
import mediaQuery from 'src/assets/styles/mediaQuery'

const HamburgerWrapper = styled.div`
  cursor: pointer;
  display: flex;
  margin-left: ${distances.m}rem;
`

const HamburgerText = styled.span`
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  margin-right: ${distances.s}rem;
  transform: translateY(5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 19rem;
  overflow: hidden;
  &.show {
    opacity: 1;
    transform: translateY(0rem);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }

  span {
    display: inline-block;
    font-weight: 700;
    color: ${colors.white};
    font-size: ${typographySizes.s}rem;
    transform: translateY(0%);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.clicked span {
    transform: translateY(-100%);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.clicked {
    opacity: 1;
    transform: translateY(0rem);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    opacity: 1;
    transform: translateY(0rem);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
`

const IconWrapper = styled.div`
  height: 19rem;
  padding-top: 1rem;
`

const topBarAnimation = keyframes`
  0% {
    transform: translateY(0rem) rotate(0);
  }

  50% {
    transform: translateY(6.5rem) rotate(0);;
  }
  100%{
    transform: translateY(6.5rem) rotate(45deg);
  }
`
const bottomBarAnimation = keyframes`
  0% {
    transform: translateY(0rem) rotate(0);;
  }

  50% {
    transform: translateY(-6.5rem) rotate(0);;
  }

  100%{
    transform: translateY(-6.5rem) rotate(-45deg);
  }
`

const bottomBarAnimationRev = keyframes`
  0% {
 
    transform: translateY(-6.5rem) rotate(-45deg);
  }

  50% {
    transform: translateY(-6.5rem) rotate(0);;
  }

  100%{
    transform: translateY(0rem) rotate(0);;
  }
`

const topBarAnimationRev = keyframes`
  0% {
    transform: translateY(6.5rem) rotate(45deg);
  }

  50% {
    transform: translateY(6.5rem) rotate(0);;
  }
  100%{
    transform: translateY(0rem) rotate(0);
  }
`

const HamburgerBar = styled.div`
  height: 7rem;
  width: 32rem;
  background-color: ${colors.white};
  position: relative;

  :first-of-type {
    top: -5rem;
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :last-of-type {
    top: 5rem;
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.hover:first-of-type {
    top: -3rem;
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.hover:last-of-type {
    top: 3rem;
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.clicked:first-of-type {
    top: -3rem;
    animation: ${topBarAnimation} 0.5s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
  &.clicked:last-of-type {
    top: 3rem;

    animation: ${bottomBarAnimation} 0.5s cubic-bezier(0.65, 0, 0.35, 1)
      forwards;
  }

  &.clicked:first-of-type {
    animation: ${topBarAnimation} 0.5s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
  &.clicked:last-of-type {
    animation: ${bottomBarAnimation} 0.5s cubic-bezier(0.65, 0, 0.35, 1)
      forwards;
  }
  &.noClicked.firstClicked:last-of-type {
    animation: ${bottomBarAnimationRev} 0.5s cubic-bezier(0.65, 0, 0.35, 1)
      forwards;
  }
  &.noClicked.firstClicked:first-of-type {
    animation: ${topBarAnimationRev} 0.5s cubic-bezier(0.65, 0, 0.35, 1)
      forwards;
  }
`

const Hamburger = props => {
  const [onHover, setOnHover] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [firstClicked, setfirstClicked] = useState(false)

  const FnOnHover = () => {
    setOnHover(true)
  }
  const FnOffHover = () => {
    setOnHover(false)
  }

  const FnClicked = () => {
    setfirstClicked(true)
    setClicked(clicked ? false : true)
  }

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <HamburgerWrapper
          onMouseEnter={FnOnHover}
          onMouseLeave={FnOffHover}
          onClick={() => {
            FnClicked()
            set({ navOpen: data.navOpen ? false : true })
          }}
        >
          <HamburgerText
            className={`${props.navOnHover && 'show'} ${
              data.navOpen && 'clicked'
            }`}
          >
            <span> MENU</span>
            <span> CLOSE</span>
          </HamburgerText>
          <IconWrapper>
            <HamburgerBar
              className={`${onHover && 'hover'} ${
                data.navOpen ? 'clicked' : 'noClicked'
              } ${firstClicked && 'firstClicked'} `}
            />
            <HamburgerBar
              className={`${onHover && 'hover'} ${
                data.navOpen ? 'clicked' : 'noClicked'
              } ${firstClicked && 'firstClicked'} `}
            />
          </IconWrapper>
        </HamburgerWrapper>
      )}
    </ContextConsumer>
  )
}

export default Hamburger
