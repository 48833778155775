import React from 'react'
import { ContextProviderComponent } from 'src/layouts/Context.js'

import Nav from 'src/components/global/NavN.js'
import GlobalStyle from 'src/assets/styles/globalStyles.js'
import Footer from 'src/components/global/Footer'
import Cookies from 'src/components/global/Cookies.js'
import { Analytics } from '@vercel/analytics/react'
import { Helmet } from 'react-helmet'

const IndexPage = ({ children }) => {
  return (
    <>
      <Cookies />

      <ContextProviderComponent>
        <Analytics />
        <GlobalStyle />
        <Nav />
        {children}
        <Footer />
      </ContextProviderComponent>
    </>
  )
}

export default IndexPage
