import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import colors from 'src/assets/styles/colors.js'
import typographySizes from 'src/assets/styles/typographySizes.js'
import mediaQuery from 'src/assets/styles/mediaQuery'
import ContextConsumer from 'src/layouts/Context.js'

const LinkWrapper = styled(Link)`
  font-size: ${typographySizes.s}rem;
  color: ${colors.white};
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  padding: 10rem;

  @media (max-width: ${mediaQuery.tablet}) {
    display: ${props => (props.offMobile ? 'none' : 'inline-block')};
  }

  @media (max-width: ${mediaQuery.mobile}) {
    font-size: 9rem;
  }

  ::before {
    content: '';
    position: absolute;
    bottom: 5rem;
    right: 10rem;
    width: calc(100% - 20rem);
    height: 2rem;
    background-color: ${colors.mainColor};
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.hover::before {
    width: 0%;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }

  ::after {
    content: '';
    position: absolute;
    bottom: 5rem;
    left: 10rem;
    width: 0%;
    height: 2rem;
    background-color: ${colors.mainColor};
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }

  &.hover::after {
    width: calc(100% - 20rem);
    transition: all 0.5s 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  }
`

const UnderlineLink = props => {
  const [onLinkOnHover, setOnLinkOnHover] = useState(false)

  const FnLinkOnHover = () => {
    setOnLinkOnHover(true)
  }
  const FnLinkOffHover = () => {
    setOnLinkOnHover(false)
  }

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <LinkWrapper
          to={props.to}
          onMouseEnter={FnLinkOnHover}
          onMouseLeave={FnLinkOffHover}
          offMobile={props.offMobile}
          className={onLinkOnHover && 'hover'}
          onClick={() => {
            set({ navOpen: false })
          }}
        >
          {props.children}
        </LinkWrapper>
      )}
    </ContextConsumer>
  )
}

export default UnderlineLink
