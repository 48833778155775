import React from 'react'

import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import colors from 'src/assets/styles/colors.js'
import typographySizes from 'src/assets/styles/typographySizes.js'
import P5Wrapper from 'src/components/p5js/p5-wrapper.js'
import Sketch from 'src/components/p5js/SketchN.js'
import MainWrapper from 'src/components/global/MainWrapper.js'
import SmallLink from 'src/components/global/buttons/SmallLink.js'
import mediaQuery from 'src/assets/styles/mediaQuery'

import sygnet from 'src/assets/svg/sygnet-orange.svg'

const FooterWrapper = styled.footer`
  padding: 20rem 0;
  background-color: ${colors.white};
  overflow-x: hidden;
  width: calc(100vw);
  /* position: fixed;
  bottom: 0;
  z-index: -1; */
`

const TopWrapper = styled(MainWrapper)`
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
`

const LinksWrapper = styled.div`
  display: flex;
  width: 50%;
  @media (max-width: ${mediaQuery.desktop}) {
    width: 75%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 30rem;
  }
  & > div {
    width: calc(50% - 20rem);
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    @media (max-width: ${mediaQuery.tablet}) {
      width: 100%;
      margin-top: 30rem;
      :first-of-type {
        margin-top: 0;
      }
    }
  }
`

const LinksTitle = styled.h2`
  font-size: ${typographySizes.m}rem;
  font-weight: 700;
  color: ${colors.black};
  margin-bottom: 20rem;
  align-self: flex-start;
  width: 100%;
  text-transform: uppercase;
  line-height: 1;
`

const FooterLink = styled(SmallLink)`
  color: ${colors.black};
  display: inline-block;
  margin-bottom: 10rem;
  width: calc(50% - 20rem);
  margin-right: 20rem;
  line-height: 1.1;
  padding: 5rem 0;
`

const BottomWrapper = styled(MainWrapper)`
  display: flex;
  height: auto;
  padding-bottom: 20rem;
  margin-top: 10rem;
  justify-content: space-between;
  ${SmallLink} {
    color: ${colors.black};
    font-weight: 700;
    :hover {
      color: ${colors.mainColor};
    }
  }
  p {
    font-size: ${typographySizes.s}rem;
    color: ${colors.black};
    text-transform: uppercase;
    font-weight: 700;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    margin-top: 30rem;
    p {
      padding: 7rem 0;
    }
  }
`

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query Footer {
          strapiGeneral {
            Social_media {
              Link
              Name
            }
            Privacy_policy_PDF {
              localFile {
                publicURL
              }
            }
          }
        }
      `}
      render={queryData => (
        <FooterWrapper>
          <TopWrapper>
            <LinksWrapper>
              <div>
                <LinksTitle>Na skróty</LinksTitle>
                <FooterLink to="/onas">O nas</FooterLink>
                <FooterLink to="/artysci">Artyści</FooterLink>
                <FooterLink to="/oferta">Oferta</FooterLink>
                <FooterLink to="/kontakt">Kontakt</FooterLink>
                <FooterLink to="/kontakt">Lokalizacje</FooterLink>
                <FooterLink to="/onas#Localizations">SPRZĘT</FooterLink>
                <FooterLink to="/wycen-swoj-mix">wyceń swój mix</FooterLink>
                <FooterLink to="/rezerwacja">zarezerwuj studio</FooterLink>
                <FooterLink
                  to={
                    queryData.strapiGeneral.Privacy_policy_PDF.localFile
                      .publicURL
                  }
                >
                  polityka prywatności
                </FooterLink>
                <FooterLink to="https://nobocoto.pl/Regulamin-sklepu-nobocoto.pdf">
                  regulamin sklepu
                </FooterLink>
              </div>
              <div>
                <LinksTitle>Social media</LinksTitle>
                {queryData.strapiGeneral.Social_media.map(pos => (
                  <FooterLink to={pos.Link} target="_blank" rel="noopener">
                    {' '}
                    {pos.Name}
                  </FooterLink>
                ))}
              </div>
            </LinksWrapper>
            <img src={sygnet} alt="nobocoto sygnet" />
          </TopWrapper>
          <P5Wrapper sketch={Sketch} />
          <BottomWrapper>
            <p>Copyright © {new Date().getFullYear()} Nobocoto.pl</p>
            <p>więcej niż studio nagrań</p>
            <p>
              Projekt i wdrożenie:{' '}
              <SmallLink
                to="https://we3studio.pl/"
                target="_blank"
                rel="noopener"
              >
                we3studio.pl
              </SmallLink>
            </p>
          </BottomWrapper>
        </FooterWrapper>
      )}
    />
  )
}

export default Footer
