import React from 'react'
import CookieConsent from 'react-cookie-consent'
import typographySizes from 'src/assets/styles/typographySizes.js'
import styled from 'styled-components'
import mediaQuery from 'src/assets/styles/mediaQuery'
import { StaticQuery, graphql } from 'gatsby'

import colors from 'src/assets/styles/colors.js'

const Link = styled.a`
  font-size: ${typographySizes.s}rem;
  text-decoration: underline;
  display: block;
  margin-top: 10rem;
  font-weight: 400;
`

const CookieWrapper = styled.div`
  position: fixed;
  right: 40rem;
  bottom: 20rem;
  z-index: 14;
  max-width: 320rem;
  @media (max-width: ${mediaQuery.tablet}) {
    right: 50%;
    transform: translateX(50%);
    top: 150rem;
    bottom: initial;
    z-index: 14;
    min-width: 320rem;
  }
`

const Text = styled.p`
  font-size: 16rem;
  line-height: 1;
  margin-bottom: 20rem;
  font-weight: bold;
  text-transform: uppercase;
`

const Cookies = () => {
  return (
    <StaticQuery
      query={graphql`
        query Cookies {
          strapiGeneral {
            Privacy_policy_PDF {
              localFile {
                publicURL
              }
            }
          }
        }
      `}
      render={queryData => (
        <CookieWrapper>
          <CookieConsent
            style={{
              background: `${colors.secColor}`,
              padding: '10rem 0',
              fontSize: `${typographySizes.s}rem`,
              width: 'auto',
              bottom: '0px',
              right: '0rem',
              left: 'initial',
              position: 'relative',
              zIndex: '14',
            }}
            buttonStyle={{
              background: `#fff`,
              margin: '5px 0px 5px 15px',
              fontSize: `${typographySizes.s}rem`,
              textTransform: 'uppercase',
            }}
            declineButtonStyle={{
              background: `transparent`,
              margin: '5px 15px 5px 5px',
              color: `${colors.black}`,
              textTransform: 'uppercase',

              fontSize: `${typographySizes.s}rem`,
            }}
            contentStyle={{
              margin: '5px 15px',
              fontSize: `${typographySizes.s}rem`,
            }}
            location="bottom"
            buttonText="Akceptuje"
            enableDeclineButton
            flipButtons
            declineButtonText="Nie akceptuje"
            cookieName="gatsby-gdpr-google-tagmanager"
          >
            <Text>
              Używamy plików cookie.{' '}
              <Link
                href={
                  queryData.strapiGeneral.Privacy_policy_PDF.localFile.publicURL
                }
                target="blank"
              >
                Zobacz, w jakim celu.
              </Link>
            </Text>
          </CookieConsent>
        </CookieWrapper>
      )}
    />
  )
}

export default Cookies
